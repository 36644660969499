<template>
  <table class="w-100">
    <tr class="border-bottom">
      <th style="width: 1%; white-space: nowrap">SubChannel</th>
      <th style="width: 60px"></th>
      <th v-for="i in months" :key="i" class="text-center">{{ i }}</th>
    </tr>
    <template v-for="(info, id) in data">
      <tr :key="id + 'plan'">
        <td style="width: 1%; white-space: nowrap" rowspan="2">{{ info.name }}</td>
        <td class="text-right pr-2 font-weight-bold">plan</td>
        <td v-for="i in months" :key="i">
          <b-input v-model="info[i].plan_percent" type="number" min="0" @change="$emit('change', getNewSettings())" />
        </td>
      </tr>
      <tr :key="id + 'fact'" class="border-bottom">
        <td class="text-right pr-2 font-weight-bold">fact</td>
        <td v-for="i in months" :key="i">
          <b-input v-model="info[i].fact_percent" type="number" min="0" @change="$emit('change', getNewSettings())" />
        </td>
      </tr>
    </template>
    <tr></tr>
  </table>
</template>
<script>
export default {
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
    subchannels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      months: Array.from({ length: 12 }, (_, i) => i + 1),
      data: {},
    };
  },
  computed: {},
  watch: {
    settings() {
      this.setTableValues();
    },
    subchannels() {
      this.setTableValues();
    },
  },
  mounted() {
    this.setTableValues();
  },

  methods: {
    findValueInSettings(id, month) {
      for (const set of this.settings) {
        if (set.sub_channel_id == id && set.month_id == month) {
          return {
            plan_percent: set.plan_percent,
            fact_percent: set.fact_percent,
          };
        }
      }
    },
    setTableValues() {
      const obj = {};
      for (const subchannel of this.subchannels) {
        const id = subchannel.id;
        if (obj[id] == null) {
          obj[id] = {};
        }
        for (const month of this.months) {
          obj[id].name = subchannel.name;
          const val = this.findValueInSettings(id, month);
          obj[id][month] = {
            fact_percent: val?.fact_percent ?? 0,
            plan_percent: val?.plan_percent ?? 0,
          };
        }
      }
      this.data = obj;
      //this.$emit('change', this.getNewSettings());
    },
    getNewSettings() {
      const res = [];
      for (const [id, subchannel_settings] of Object.entries(this.data)) {
        if (this.subchannels.find((x) => x.id == id))
          for (const month of this.months) {
            const value = subchannel_settings[month];
            const setting = {
              sub_channel_id: id,
              month_id: month,
              plan_percent: parseFloat(value.plan_percent) || 0,
              fact_percent: parseFloat(value.fact_percent) || 0,
            };
            res.push(setting);
          }
      }
      return res;
    },
  },
};
</script>
<style scoped></style>
