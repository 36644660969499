<template>
  <b-input type="number" min="0" :value="percents" @input="(val) => sendValue(val)" @keypress="isNumber($event)"></b-input>
</template>
<script>
export default {
  name: 'AuctionInput',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      percents: this.value * 100,
    };
  },
  methods: {
    sendValue(val) {
      this.$emit('input', parseInt(val) / 100);
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped></style>
