<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.channels')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
        <!--Search-->
        <div class="d-flex align-content-center justify-content-between mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <!-- <b-form-checkbox v-if="canRestore" @input="pageClick(1)" v-model="filterShowTrashed" class="mt-2" switch>
              {{$t('table.showOnlyTrashed')}}
            </b-form-checkbox> -->
            <MultiSelect
              v-model="filterYear"
              :options="yearsList"
              :multiple="false"
              :allow-empty="false"
              :placeholder="$t('channelModal.pickYear')"
              class="w-250px"
              name="id"
              track-by="id"
              label="id"
              selectLabel="id"
              data-automatic="ms-year"
            />
          </div>
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th style="width: 60%">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('sideBar.channels') }}
              </th>
              <th>
                <sort-arrows :sort-string="sortString" :column="'channel_company_id,'" @click="sortTable('channel_company_id,', $event)" />
                {{ $t('channelModal.company') }}
              </th>
              <th>
                <sort-arrows :sort-string="sortString" :column="'saleshouse_id,'" @click="sortTable('saleshouse_id,', $event)" />
                {{ $t('channelModal.salesHouses') }}
              </th>
              <th scope="col" style="min-width: 100px">
                <sort-arrows :sort-string="sortString" :column="'is_visible,'" @click="sortTable('is_visible,', $event)" />
                {{ $t('table.isVisible') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="channelsStatus !== 'success'">
              <td colspan="3"><SpinnerLoader :loading="channelsStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>{{ row.id }}</td>
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <template v-if="!row.deleted_at">
                      <button v-if="canView" class="btn-icon" :title="canEdit ? $t('table.edit') : $t('table.details')" @click="showModalEditChannels(row)">
                        <b-icon icon="pencil"></b-icon>
                      </button>
                      <router-link v-if="canViewUsers" :to="`users?channel_id=${row.id}`" class="btn-icon" :title="$t('sideBar.users')">
                        <b-icon icon="people"></b-icon>
                      </router-link>
                      <button v-if="canDelete" class="btn-icon" :title="$t('table.delete')" @click="showModalDeleteChannels(row)">
                        <b-icon icon="trash"></b-icon>
                      </button>
                    </template>
                    <button
                      v-else-if="row.deleted_at && canRestore"
                      class="btn-icon"
                      :title="$t('table.restore')"
                      @click="showModalRestoreEntity(row, 'PUT_RESTORE_CHANNELS', $t('table.restoreChannel'))"
                    >
                      <b-icon icon="plus"></b-icon>
                    </button>
                    {{ row.name }}
                  </div>
                </td>
                <td>{{ row.channel_company ? row.channel_company.name : '' }}</td>
                <td>{{ row.channel_settings ? row.channel_settings[filterYear?.id]?.saleshouse?.name ?? '' : '' }}</td>
                <td>
                  <b-form-checkbox
                    :id="`checkbox-channel-visibility-${index}`"
                    :checked="row.is_visible"
                    :name="`checkbox-channel-visibility-${index}`"
                    :disabled="!canEdit"
                    @change="toggleVisibility(row)"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr v-if="channelsStatus === 'success' && !tableData.length">
                <td colspan="3">
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('channel.create')" variant="primary" @click="showModalAddChannels">
          {{ $t('table.addNewChannels') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD-->
      <ModalChannels
        :is-open="isOpenChannelModal"
        :current-item="currentItem"
        :can-edit="canEdit"
        @getChannels="paramsData"
        @updateChannels="updateChannelHandler"
        @openModal="isOpenChannelModal = false"
      />

      <!--MODAL DELETE-->
      <b-modal
        ref="delete-channels-confirm"
        size="sm"
        :title="$t('table.deleteChannels')"
        :ok-title="$t('table.yes')"
        :cancel-title="$t('table.no')"
        auto-focus-button="ok"
        :busy="isModalBusy"
        @ok.prevent="deleteChannels"
      >
        <p class="my-2">
          {{ $t('table.confirmDeleteChannels') }} <span class="text-danger">{{ rowId.name }}</span>
          ?
        </p>
      </b-modal>
      <!--MODAL DELETE-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import ModalChannels from '../components/Channels/ModalChannels';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';

export default {
  name: 'Channels',
  components: {
    PaginateWrapper,
    SpinnerLoader,
    VocabularyTitle,
    SearchDropdown,
    SearchInput,
    ModalChannels,
    SortArrows,
    MultiSelect,
  },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },
  data() {
    return {
      rowId: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterYear: { id: +new Date().getFullYear() },
      filterPage: 1,
      selectedPerPage: '100',

      isOpenChannelModal: false,
      currentItem: null,
      filterShowTrashed: false,
      isModalBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      yearsList: 'getYear',
      channels: 'getChannels',
      channelsStatus: 'getChannelsStatus',
    }),
    canView() {
      return this.$checkPermissions('channel.read');
    },
    canEdit() {
      return this.$checkPermissions('channel.update');
    },
    canDelete() {
      return this.$checkPermissions('channel.delete');
    },
    canViewUsers() {
      return this.$checkPermissions('user.read_list');
    },
    canRestore() {
      return this.$checkPermissions('channel.restore');
    },
  },
  watch: {
    channels() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.channels') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getChannelsInfo();
  },
  destroyed() {
    this.$store.commit('clearChannelsList');
    this.$store.commit('clearChannelCompanies');
    // cleanup dirty data with $isDisabled years
    this.$store.commit('clearYearsList');
  },
  methods: {
    updateChannelHandler() {
      this.isOpenChannelModal = false;
      this.currentItem = null;
    },

    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_CHANNELS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        include: 'channelSettings.saleshouse,channelCompany',
        'filter[trashed]': this.filterShowTrashed ? 'only' : undefined,
        'filter[is_visible]': 'all', // to show visible + invisible channels
      });
      this.$store.dispatch('GET_YEAR'), // reload years list with no modification
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.channels) {
        this.tableData = this.channels.data;
        this.paginationData = this.channels.pagination;
        if (this.filterPage > this.channels.pagination.last_page) {
          this.filterPage = this.channels.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getChannelsInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    async showModalAddChannels() {
      this.isOpenChannelModal = true;
    },

    async showModalEditChannels(data) {
      this.currentItem = data;
      this.isOpenChannelModal = true;
    },

    async showModalDeleteChannels(data) {
      this.rowId = data;
      this.$refs['delete-channels-confirm'].show();
    },
    hideModalDeleteChannelsConfirm() {
      this.$refs['delete-channels-confirm'].hide();
    },
    async deleteChannels() {
      this.isModalBusy = true;
      const formData = this.rowId.id;
      await this.$store.dispatch('DELETE_CHANNELS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteChannels'),
            text: this.rowId.name,
          });
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.paramsData();
      this.hideModalDeleteChannelsConfirm();
      this.isModalBusy = false;
    },

    async toggleVisibility(row) {
      const formData = {
        id: row.id,
        name: row.name,
        is_visible: !row.is_visible,
      };
      await this.$store.dispatch('PUT_CHANNELS', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
            text: row.name,
          });
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          row.is_visible = !row.is_visible; // restore previous state
        },
      });
    },
  },
};
</script>

<style lang="sass"></style>
